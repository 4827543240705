import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>О нас | Tennis Club Coslada</title>
        <meta name={"description"} content={"Ваше частное теннисное убежище"} />
        <meta
          property={"og:title"}
          content={"О нас | Tennis Club Coslada"}
        />
        <meta
          property={"og:description"}
          content={"Ваше частное теннисное убежище"}
        />
        <meta
          property={"og:image"}
          content={"https://claricorefriz.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://claricorefriz.com/img/2346346.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://claricorefriz.com/img/2346346.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://claricorefriz.com/img/2346346.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://claricorefriz.com/img/2346346.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://claricorefriz.com/img/2346346.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://claricorefriz.com/img/2346346.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="--color-primary"
        padding="60px 0"
        sm-padding="40px 0"
      >
        <Box
          margin="-16px -16px -16px -16px"
          padding="0px 0px 0px 0px"
          display="flex"
          width=" "
          flex-wrap="wrap"
        >
          <Box
            padding="16px 16px 16px 16px"
            display="flex"
            width="50%"
            lg-width="100%"
          >
            <Box
              padding="98px 64px 98px 64px"
              display="flex"
              flex-direction="column"
              background="--color-light"
              color="--dark"
            >
              <Text
                as="h4"
                margin="12px 0"
                font="--base"
                color="--grey"
                letter-spacing="1px"
                text-transform="uppercase"
              >
               О нас
              </Text>
              <Text
                as="h2"
                margin="12px 0"
                font="--headline2"
                md-font="--headline3"
              >
               История теннисного клуба Coslada
              </Text>
            </Box>
          </Box>
          <Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
            <Box
              padding="16px 16px 16px 16px"
              display="flex"
              width="100%"
              lg-width="33.3333%"
              md-width="100%"
              sm-width="100%"
            >
              <Box
                padding="0px 0px 624px 0px"
                display="flex"
                flex-direction="column"
                background="url(https://claricorefriz.com/img/3.jpg) center/cover"
                width="100%"
                lg-padding="0px 0px 248px 0px"
                sm-padding="0px 0px 380px 0px"
              />
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--lead"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
           Теннисный клуб Coslada родился из страсти к теннису и желания создать уникальное и спокойное место для игроков, чтобы они могли наслаждаться этим видом спорта. Наш основатель представлял себе место, где любители тенниса могли бы отвлечься от повседневных забот и погрузиться в игру. С того момента, как вы ступите на наш тщательно ухоженный глиняный корт, вы почувствуете разницу. Успокаивающая атмосфера в сочетании с высококачественным игровым покрытием обеспечивает непревзойденный опыт игры в теннис.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://claricorefriz.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Уникальные особенности нашего корта
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
           Наш глиняный корт - центральный элемент Tennis Club Coslada, созданный для того, чтобы предложить исключительный опыт игры. Глиняная поверхность не только легче для ваших суставов, но и позволяет проводить стратегические и более продолжительные ралли. Наш частично закрытый дизайн гарантирует, что вы сможете комфортно играть независимо от погоды, обеспечивая тень в солнечные дни и защиту от дождя.
            <br />
            <br />
            Помимо корта, мы предлагаем целый ряд удобств, которые сделают ваше посещение более комфортным. Наша уютная лаунж-зона - идеальное место для отдыха до или после игры. К вашим услугам бесплатные прохладительные напитки, а наш персонал всегда готов помочь вам в решении любых вопросов. Мы стремимся сделать каждый визит в Tennis Club Coslada незабываемым, начиная с качества наших объектов и заканчивая индивидуальным обслуживанием.
          </Text>
        </Box>
      </Section>
      <Section
        padding="100px 0 120px 0"
        sm-padding="80px 0 90px 0"
        quarkly-title="Statistics-10"
      >
        <Text
          margin="0px 0px 20px 0px"
          letter-spacing="2px"
          color="#cd9f55"
          font="normal 500 20px/1.5 --fontFamily-sansVerdana"
          border-color="#dca654"
        >
          Станьте частью опыта Tennis Club Coslada
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          lg-flex-direction="column"
        >
          <Box min-width="100px" min-height="100px" lg-width="100%">
            <Text
              margin="0 0px 0px 0px"
              font="normal 600 26px/1.2 --fontFamily-serifGeorgia"
              color="#050c32"
              sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia"
            >
              Создайте свою историю тенниса вместе с нами в Tennis Club Coslada. Если вы хотите улучшить свои навыки, насладиться неспешной игрой или просто отвлечься от повседневных забот, наш корт предложит вам идеальные условия. Ощутите спокойствие и эксклюзивность, которые отличают нас от других теннисных центров. Мы стремимся обеспечить вам уникальный и приятный опыт игры в теннис, в который вы захотите возвращаться снова и снова.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
